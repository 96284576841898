.carousel .control-dots .dot {
  width: 20px;
  height: 20px;
  border-radius: 0;
  box-shadow: none;
  transition: background 0.25s ease-in;
  opacity: 1;
}

.carousel .control-dots .dot.selected {
  background: #f9ad49;
}

@media only screen and (max-width: 599px) {
  .carousel .control-dots .dot {
    width: 10px;
    height: 10px;
  }
}
